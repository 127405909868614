import $ from 'jquery';

// Init Treeview later
$(window).off("load.lte.treeview");

// Treeview
var Treeview = adminlte.Treeview;
Treeview.prototype._toggle = Treeview.prototype.toggle;
Treeview.prototype.toggle = function toggle(event) {
	let $currentTarget = $(event.currentTarget),
		treeviewMenu = $currentTarget.next(),
		href = $currentTarget.attr("href"),
		isText = event.target.tagName == "P";
	if (!treeviewMenu.is(".nav-treeview") || isText && href && href != "#" && href != "javascript:void(0);") // Menu text with href
		return;
	this._toggle(event);
	event.stopImmediatePropagation();
};

// Dropdown menu parent item with href // Override AdminLTE
$("ul.dropdown-menu [data-toggle=dropdown]").on("click", function(e) {
	let href = $(this).attr("href");
	if (href && href != "#" && e.target.nodeName == "SPAN")
		window.location = href;
});
